/// General layout

.container {
  margin: 0 auto;
  max-width: 1400px;
  width: 95%;

  &-small {
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
  }

  &-wide {
    max-width: 1800px;
    width: 90%;
    margin: 0 auto;
  }
}

section {
  padding: 3rem 0;
}

.checkout-bg {

    background: linear-gradient(90deg, rgba(247,250,252,1) 0%, rgba(247,250,252,1) 50%, rgba(255,255,255,1) 50%);
}

.checkout-bg-inner {
  background: linear-gradient(90deg, rgba(247,250,252,1) 0%, rgba(247,250,252,1) 50%, rgba(255,255,255,1) 50%);
}

.steps {
  min-width: 600px;
}

.steps ul {
  display: flex;
  display: -webkit-flex;
  margin-bottom: 0;
  padding: 0;
}

.steps ul li {
  list-style: none;
  display: block;
}

.steps ul li {
  color: #aaa;
}

.steps ul li a {
  color: #aaa;
  text-decoration: none;
  display: inline-block;
  padding: 8px;
}

.steps ul li.done {
  color: #000;
  opacity: 0.5;
}

.steps ul li.done a {
  color: #000;
}

.steps ul li.sel {
  color: #000;
  opacity: 1;
}

.steps ul li.sel a {
  color: #000;
}

.steps .meter {
  position: relative;
  background-color: #e2e8f0;
}

.steps .meter > span {
  display: block;
  height: 4px;
  background-color: #000;
  position: relative;
  overflow: hidden;
}
@media (max-width: 750px) {
  .steps ul {
    display: block;
    margin-left: 24px;
  }

  .steps ul li {
    list-style: decimal;
    display: list-item;
  }

  .steps .meter {
    display: none;
  }
}
